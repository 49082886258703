import Loading from '../lib/loading';
import jqueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import { Drawer, AjaxForms, ScrollEffects } from 'pickles';

export default {
  init() {

    window.$ = window.$ || jQuery;
    
    Loading.init();

    // carousels

    Flickity.setJQuery($);
    jqueryBridget('flickity', Flickity, $);
    
    $('.carousel').flickity({
      // options
      groupCells: 1,
      adaptiveHeight: true,
      prevNextButtons: false,
      autoPlay: true,
      wrapAround: true,
      pageDots: false,
    });

    // drawer

    if ( !$('html').hasClass('initialized') ) {
      // drawer
      const drawer = new Drawer({
        contentSelector: '#masthead, #content, #footer'
      });
      $('html').addClass('initialized');
    }
    
    // mobile menu 

    $('.section--product-menu select')
      .off('change')
      .on('change', e => {
        window.location = $(e.currentTarget).val()
      })

    // scroll fx

    new ScrollEffects();
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
